import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import GetText from '../GetText';
//Components
import Input from '../Input';
//Styles
import { Wrapper, Content } from './SearchBar.styles';

const SearchBar = ({ setSearchTerm}) => {

    const {
        search
    } = useParams();

    const [state, setState] = useState(' ');
    const [showPlaceholder, setShowPlaceholder] = useState(true);

    const inital = useRef(true);
    const navigate = useNavigate();



    useEffect(() => {

        if(inital.current === true) {
            inital.current = false;
            return;
        }

        const timer = setTimeout(() => {

            if(state) {
                navigate('/');
            }
            else
            {

                navigate('/');
                setState('');
            }

            setSearchTerm(state);

        }, 500)

        return () => clearTimeout(timer)
    }, [setSearchTerm, state])

        /* Fill SearchBar Field with Search Value */
        if(state == ' ') {
            setState(search);
        }
        /* Fill SearchBar Field with Search Value End */

    var searchbarId = document.getElementById("searchbar");
    
    if(!showPlaceholder) {
        if (searchbarId) {
            searchbarId.focus();
        }
    }


    return (
        <Wrapper>
            <Content>
                <i className="fa-solid fa-magnifying-glass"></i>
                {
                    (showPlaceholder) ? 
                        (!state) ?
                            <span className='placeholder' onClick={event => setShowPlaceholder(false)}><GetText textName={'searchbar_placeholder'} /></span>
                        :null
                    :null
                }
                <Input
                id='searchbar'
                type='text'
                name='search'
                placeholder={''}
                onChange={event => setState(event.currentTarget.value)}
                onClick={event => setShowPlaceholder(false)}
                autoComplete='off'
                value={state}
                />
                {
                    (state) ? <span onClick={event => { navigate('/'); setState(''); } }><i className="fa-solid fa-xmark"></i></span> :null
                }
            </Content>
        </Wrapper>
    )
};

export default SearchBar;