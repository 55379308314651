import styled from 'styled-components';

export const Wrapper = styled.div`
    display: flex;
    width:100%;
    align-items: center;
    height: 310px;
    background: var(--color-bg-10);
    padding: var(--padding-left-right);
`;

export const Content = styled.div`
    width: var(--maxWidth);
    padding: 10px 0;
    color: var(--color-5);
    margin:auto;
    margin-bottom:85px;

    a, span {
        display:block;
        font-size: var(--fontMed);
        margin: 8px 0;
        width: max-content;
        height: 28px;
        color: var(--color-5);
    }
    span {
        padding-top:10px;
        font-size: var(--fontSmall);
    }
    select {
        font-size: var(--fontMed);
        border-radius: 3px;
    }
`;