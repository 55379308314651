import styled from 'styled-components';

export const Wrapper = styled.div`
    width:100%;
    max-width: var(--maxWidth);
    min-height: 100vh;
    display: flex;
    margin: auto;
    margin-top:90px;
    margin-bottom:10px;
`