import { useState, useEffect } from 'react';
// API
import API from '../API';
//Functions
import UserFunctions from '../components/UserFunctions';

const initialState = {
    page: 0,
    results: [],
    total_pages: 0,
    total_results: 0
};

export const usePostsFetch = () => {
    const [searchTerm, setSearchTerm] = useState('');
    const [searchFilter, setSearchFilter] = useState('');
    const [stats, setStats] = useState('');
    const [state, setState] = useState(initialState);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [isLoadingMore, setIsLoadingMore] = useState(false);
    const [canLoadMore, setCanLoadMore] = useState(true);
    
    const fetchPosts = async (page, searchTerm = '', searchFilter) => {
        try {
            setError(false);
            setLoading(true);

            const posts = await API.fetchPosts(searchTerm, page, searchFilter);

            if(page === 0) {
                const getStats = await API.fetchStats(UserFunctions.getSessionHash());
                setStats(getStats.results);
            }

            if(posts.results) {

                setState(prev => ({
                    ...posts,
                    results: page > 0 ? [...prev.results, ...posts.results] : [...posts.results]
                }))
            }
            else
            {
                setCanLoadMore(false);
            }

        } catch (error) {
            setError(true);
        }
        setLoading(false);
    };

    // Search and initial
    useEffect(() => {
        setState(initialState);
        fetchPosts(0, searchTerm, searchFilter);
    }, [searchTerm, searchFilter]);

    // Load More
    useEffect(() => {
        if(!isLoadingMore) return;
        var newPage = parseInt(state.page) + 1;
        fetchPosts(newPage, searchTerm, searchFilter); //Get Post with actually searchTerm and new Page Number
        setIsLoadingMore(false);
    }, [isLoadingMore, searchTerm, searchFilter, state.page]);

    return { state, stats, loading, error, searchTerm, searchFilter, canLoadMore, setSearchTerm, setIsLoadingMore, setCanLoadMore, setSearchFilter };
};