/* Cookie */
function setCookie(name,value,days) {
  var expires = "";
  if (days) {
      var date = new Date();
      date.setTime(date.getTime() + (days*24*60*60*1000));
      expires = "; expires=" + date.toUTCString();
  }
  document.cookie = name + "=" + (value+";secure" || "")  + expires + "; path=/";
}
function getCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for(var i=0;i < ca.length;i++) {
        var c = ca[i];
        while (c.charAt(0)==' ') c = c.substring(1,c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
    }
    return null;
}
function eraseCookie(name) {   
    document.cookie = name +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
}
/* Cookie End */

var UserFunctions = (function() {

    var userId;
    var userName;
    var userLanguage;
    var sessionHash;
    var darkMode;
  
    /* userId */
    var setUserId = function(getuserId) {
      userId = getuserId;
      // Also set this in cookie/localStorage
      setCookie('sessionUserId', userId);
    };

    var getUserId = function() {
      //from cookie/localStorage
      userId = getCookie('sessionUserId');

      return userId;
    };
    var deleteUserId = function() {
      //delete this cookies
      eraseCookie('sessionUserId')
    };
    /* userId end */

    /* username */
    var setUserName = function(getUserName) {
      userName = getUserName;
      // Also set this in cookie/localStorage
      setCookie('sessionUserName', userName);
    };

    var getUserName = function() {
      //from cookie/localStorage
      userName = getCookie('sessionUserName');
      return userName;
    };
    var deleteUserName = function() {
      //delete this cookies
      eraseCookie('sessionUserName')
    };
    /* username end */

    /* Language */
    var setUserLanguage = function(getUserLanguage) {
      userLanguage = getUserLanguage;
      // Also set this in cookie/localStorage
      setCookie('sessionUserLanguage', userLanguage);
    };

    var getUserLanguage = function() {
      //from cookie/localStorage
      userLanguage = getCookie('sessionUserLanguage');
      return userLanguage;
    };
    var deleteUserLanguage = function() {
      //delete this cookies
      eraseCookie('sessionUserLanguage')
    };
    /* Language end */

    /* session */
    var setSessionHash = function(getSessionHash) {
      sessionHash = getSessionHash;     
      setCookie('sessionSessionHash', sessionHash);
      // Also set this in cookie/localStorage
    };

    var getSessionHash = function() {
          
      //from cookie/localStorage
      sessionHash = getCookie('sessionSessionHash');

      return sessionHash;
    };
    var deleteSessionHash = function() {
      //delete this cookies
      eraseCookie('sessionSessionHash')
    };
    /* session end */
    /* darkMode */
     var setDarkMode = function(getDarkMode) {
      darkMode = getDarkMode;
      // Also set this in cookie/localStorage
      setCookie('sessionDarkMode', darkMode);
    };

    var getDarkMode = function() {
      //from cookie/localStorage
      darkMode = getCookie('sessionDarkMode');
      return darkMode;
    };
    var deleteDarkMode = function() {
      //delete this cookies
      eraseCookie('sessionDarkMode')
    };
    /* darkMode end */

    return {
        getUserId: getUserId,
        setUserId: setUserId,
        getUserName: getUserName,
        setUserName: setUserName,
        getSessionHash: getSessionHash,
        setSessionHash: setSessionHash,
        getDarkMode: getDarkMode,
        setDarkMode: setDarkMode,
        getUserLanguage: getUserLanguage,
        setUserLanguage: setUserLanguage,
        deleteUserLanguage: deleteUserLanguage,
        deleteUserId: deleteUserId,
        deleteUserName: deleteUserName,
        deleteSessionHash: deleteSessionHash,
        deleteDarkMode: deleteDarkMode
    }
  
  })();
  
export default UserFunctions;