import React from 'react';
import { Link } from 'react-router-dom';
//Components
import GetText from '../GetText';
//Functions
import UserFunctions from '../UserFunctions';
//Styles
import { Wrapper, Content } from './Footer.styles';

const Footer = () => {

    /* Language */

    if(UserFunctions.getUserLanguage() == null) {

        var userLang = navigator.language || navigator.userLanguage; // de, en, ...
        UserFunctions.setUserLanguage(userLang);

    }

    const handleUserLanguage = e => {
        var valueUserLanguage = e.currentTarget.value;
        //UserLanguage
        if(valueUserLanguage != null) {
            UserFunctions.deleteUserLanguage();
            UserFunctions.setUserLanguage(valueUserLanguage);
            window.location.reload(true);//Refresh is actually necessary
        }
    }
  
    /* Language End */

    return (
        <Wrapper>
            <Content>
                <Link to='/legal/about' className='hover'>{<GetText textName='about' />}</Link>
                <Link to='/legal/terms' className='hover'>{<GetText textName='terms' />}</Link>
                <Link to='/legal/privacy' className='hover'>{<GetText textName='privacy' />}</Link>

                {
                    (UserFunctions.getUserLanguage() == 'de') ? 
                        <select onChange={handleUserLanguage}>
                            <option value='de'>Deutsch</option>
                            <option value='en'>English</option>
                        </select>
                    :
                        <select onChange={handleUserLanguage}>
                            <option value='en'>English</option>
                            <option value='de'>Deutsch</option>
                        </select>
                }

                <span>&copy; {new Date().getFullYear()} Report4Feed</span>
            </Content>
        </Wrapper>
    )
};

export default Footer;