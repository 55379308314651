import React from 'react';
import { useNavigate } from 'react-router-dom';
//Functions
import UserFunctions from './UserFunctions';
// Components
import CreatePost from './CreatePost';
import Login from './Login';
import Header from './Header';
import Wrapper from './Wrapper'
import Footer from './Footer';
// Styles
import { Wrapper as PostCreateWrapper } from './PostCreate/PostCreate.styles'

const PostCreate = () => {
    const navigate = useNavigate();

    if(!UserFunctions.getSessionHash()) {
        navigate('/login')
    }

    return (
        (UserFunctions.getSessionHash()) ? (
            <>
                <Header userName={UserFunctions.getUserName()} />
                <Wrapper>
                    <PostCreateWrapper>
                        <CreatePost userHash={UserFunctions.getSessionHash()} />
                    </PostCreateWrapper>
                </Wrapper>
                <Footer />
            </>
        )
        :
        (
            <><Login /></>
        )
    )
};

export default PostCreate;