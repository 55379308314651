import styled from "styled-components";

export const Wrapper = styled.div`
button {
margin:auto;
display:inline-block;
margin: var(--margin-left-right);
margin-top:12px;
background: var(--color-bg-1);
color: var(--color-2);
}

input, textarea {
background: var(--color-bg-2);
border:2px solid;
border-color: var(--color-border-1);
border-radius:6px;
padding:12px;
}

textarea {
resize: vertical;
}

span {
    display:block;
    color: var(--color-4);
    margin-left:12px;
    margin-top:6px;
    font-size: var(--fontSmall);
}

.reportIcon {
cursor:pointer;
color: var(--color-8) !important;
font-size: var(--fontMed);
position: relative;
float: right;

    :hover, :focus {
        opacity:0.5;
    }
}

.reportBoxWrapper {
    text-align:center;
    width:101vw;
    height:101vh;
    background: var(--color-bg-1);
    padding: var(--padding-all);
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index:1000;
    display:none;
    overflow:hidden;
}
.reportBoxWrapperActive {
    display:block !important;
}

.reportBox {
    text-align:center;
    margin:auto;
    width:95%;
    max-width: var(--mediumWidth);
    min-height:150px;
    background: var(--color-bg-2);
    box-shadow: var(--box-shadow-1);
    border-radius:12px;
    padding: var(--padding-all);
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index:1001;
}

.moderationBoxWrapper {
    width:100%;
    max-width: var(--mediumWidth);
    text-align:center;
    background: var(--color-bg-2);
    padding: var(--padding-all);
    border-radius:12px;
    border:2px solid;
    border-color: var(--color-border-1);
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index:1000;
    display:none;
    overflow:hidden;
}
.moderationBoxWrapperActive {
    display:block !important;
}

.moderationBox {
    text-align:center;
    margin:auto;
    width:95%;
    max-width: var(--mediumWidth);
    min-height:150px;
    background: var(--color-bg-2);
    box-shadow: var(--box-shadow-1);
    border-radius:12px;
    padding: var(--padding-all);
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index:1001;
}
`;