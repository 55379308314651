import React, { useState, useEffect } from 'react';
//API
import API from '../../API';
//Components
import Button from '../Button'
import GetText from '../GetText';
import Input from '../Input';
import Textarea from '../Textarea';
import Spinner from '../Spinner';
//Styles
import { Wrapper } from './Moderation.style';

const Moderation = ({ postId, answerId, userHash, answer, post }) => {


    const [permissions, setPermissions] = useState();
    const [reportBox, setReportBox] = useState(false);
    const [moderationBox, setModerationBox] = useState(false);
    const [postTitle, setPost] = useState(post);
    const [postAnswer, setAnswer] = useState(answer);
    const [reported, setReported] = useState(false);
    const [reportStep, setReportStep] = useState(0);
    const [postDeleted, setPostDeleted] = useState(false);
    const [answerDeleted, setAnswerDeleted] = useState(false);
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(true);

    const handleInput = e => {
        const name = e.currentTarget.name;
        const value = e.currentTarget.value;

        if(name === 'post') setPost(value);
        if(name === 'answer') setAnswer(value);

    };

    const constModeration = async (GetAction, GetPostId, GetAnswerId, GetUserHash, GetPostTitle, GetPostAnswer) => {

        try {
            setError(false);
            setLoading(true);
    
            const data = await API.moderation(GetAction, GetPostId, GetAnswerId, GetUserHash, null, GetPostTitle, GetPostAnswer);

            //Check permissions
            if(data.results[0].permissions) {
                setPermissions(data.results[0].permissions);
            }

            //Check reported
            if(data.results[0].reported) {
                setReported(true);
            }

            //Action
            if(GetAction == 'update') {
                if(data.results[0].success == true) {
                    if(GetPostTitle) {

                    }
                    if(GetPostAnswer) {

                    }
                    setModerationBox(false);
                }
            }

            if(GetAction == 'delete') {
                if(data.results[0].success == true) {
                    if(GetPostId) {
                        setPostDeleted(true);
                        setPost(null);
                    }
                    if(GetAnswerId) {
                        setAnswerDeleted(true);
                        setAnswer(null)
                    }
                    setModerationBox(false);
                }
            }

            if(GetAction == 'report') {
                if(data.results[0].success == true) {
                    setReported(true);
                }
            }

        } catch (error) {
            setError(true);
        }
        setLoading(false);
    };

    // Load More
    useEffect(() => {
        if(loading == false) {
            return;
        }
        else
        {
            constModeration(null, postId, answerId, userHash, null, null); //Check Permissions and Post/Answer Data
        }

    }, [loading, postId, answerId, userHash]);


    if(loading) return <Spinner />
    if(error) return <div>Something went wrong...</div>

    return (
        <>
            {
                //Logged in
                (userHash) ? (
                    <>
                        {
                            //Permissions -1
                            ((permissions == -1) || (permissions == 1)) ? (
                                <Wrapper>
                                    {
                                        (postId || answerId) ? 
                                            <Button callback={() => { setModerationBox(true) }} text={'Moderation'} />
                                        :null
                                    }
                                    <div className={ `${(moderationBox == true) ? 'moderationBoxWrapper moderationBoxWrapperActive' : 'moderationBoxWrapper'}` }>
                                        {
                                            ((postId) && (postDeleted == false) && (permissions == -1)) ? 
                                                <Button callback={async () => {await constModeration('delete', postId, null, userHash);} } text={'Champion löschen'} />
                                            :null
                                        }
                                        {
                                            (answerId && (answerDeleted == false)) ?
                                                <Button callback={async () => {await constModeration('delete', null, answerId, userHash);} } text={<GetText textName={'deleteAnswer'} /> } />
                                            :null
                                        }
                                        {
                                        ((postId) && (postDeleted == false) && (permissions == -1)) ? 
                                            <>
                                                <span>
                                                    Post Id: {postId}
                                                </span>
                                                <Input value={postTitle} name='post' onChange={handleInput} />
                                                <Button callback={async () => {await constModeration('update', postId, null, userHash, postTitle, null);} } text={<GetText textName={'save'} /> } />
                        <br></br>
                    </>
                                        :null
                                        }
                                        {
                                        (answerId && (answerDeleted == false) && (permissions == -1)) ?
                                            <>
                                                <span>
                                                    Answer Id: {answerId}
                                                </span>
                                                <Textarea value={postAnswer} name='answer' onChange={handleInput} />
                                                <Button callback={async () => {await constModeration('update', null, answerId, userHash, null, postAnswer);} } text={<GetText textName={'save'} /> } />
                                                <br></br>
                                            </>
                                            :null
                                        }
                                        {
                                            <Button callback={() => { setModerationBox(false) }} text={<GetText textName={'dismiss'} />} />
                                        }
                                    </div>
                                </Wrapper>
                            ):null
                        }
                    </>
                ):null
            }
        </>
    )
};

export default Moderation;