import React from 'react';
import Spinner from './Spinner';
import SearchBar from './SearchBar';
import PostInfo from './PostInfo';
import GetText from './GetText';
import Header from './Header';
import CreatePost from './CreatePost';
import Footer from './Footer'
import Wrapper from './Wrapper/'
//Styles
import { Wrapper as HomeWrapper } from './Home/Home.styles';
//Functions
import UserFunctions from './UserFunctions';
// Hook
import { usePostsFetch } from '../hooks/usePostsFetch';


const Home = () => {

    const {
        state,
        loading, 
        error, 
        searchTerm,
        canLoadMore,
        setSearchTerm,
        setIsLoadingMore,
        setCanLoadMore,
    } = usePostsFetch();

    //if (error) return <div>Something went wrong ...</div>

    //Load Posts when scrolling
    document.getElementsByTagName('body')[0].onscroll = () => {

        if((document.getElementById('homeWrapper') !== null) && (error === false)) {
            var windowPosition = document.documentElement.scrollTop;
            var homeWrapperHeight = document.getElementById("homeWrapper").clientHeight;
        
            var windowHeight = window.innerHeight;

            var calculation = (windowPosition + windowHeight);

            var calculationTwo = (homeWrapperHeight / 100)
            calculationTwo = (calculationTwo * 90);

            if((calculation >= calculationTwo) && (!loading) && (canLoadMore === true)) {
                setIsLoadingMore(true);
            }
            else
            {
                if(windowPosition <= (homeWrapperHeight / 2)) {
                    if(!searchTerm) {
                        setCanLoadMore(true);
                    }
                }
            }
        }
	};
    ////Load Posts when scrolling end

    return (
        <>
            <Header userName={UserFunctions.getUserName()} />
            <SearchBar setSearchTerm={setSearchTerm} userHash={UserFunctions.getSessionHash()}/>
                <HomeWrapper id='homeWrapper'>
                    {
                        //Show Search Results Title "Search Result: <placeholder>"
                        (searchTerm && state.results[0]) ? (
                            <>
                                <h2 className='searchResultsTitle'>{searchTerm}</h2>
                                <p className='searchResultsTitle'>{<GetText textName='searchResults' />}:</p>
                            </>
                        )
                        :null
                        //Show Search Results Title "Search Result: <placeholder>" End
                    }
                    {
                        //If Results not null show posts
                        state.results.map(row => (
                            <PostInfo key={row.id} postData={row} userHash={UserFunctions.getSessionHash()} />
                        ))
                    }
                    {
                        loading ? (
                            <Spinner />
                        )
                        :
                        (
                            state.results[0] ? (
                                //Show load more button for more results
                                //<Button text='Load More' callback={() => setIsLoadingMore(true)} userHash={UserFunctions.getSessionHash()}/>
                                <></>
                            )
                            :
                            (
                                //No Results = Create post
                                <CreatePost value={searchTerm} userHash={UserFunctions.getSessionHash()} />
                            )
                        )
                    }
                </HomeWrapper>
            <Footer />
        </>
    )
};
export default Home;