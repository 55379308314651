import React from 'react';
// Config
import { IMG_URL } from '../../config'
//Functions
import UserFunctions from '../UserFunctions';
//Components
import Spinner from '../Spinner';
import PostInfo from '../PostInfo';
import GetText from '../GetText';
import UserImage from '../UserImage';
import UserDefaultImage from '../../images/user/profile-img-default.png'
//Hook
import { useUserFetch } from '../../hooks/useUserFetch';
// Styles
import { Wrapper, ProfileHeader } from './UserInfo.styles';

import UserVerifiedImage from '../../images/user/verified-badge.png'

const UserInfo = ({userName, userHash}) => {

    const {
        state,
        loading, 
        error,
        notFound
    } = useUserFetch(userName, userHash);

    if(loading) return <Spinner />
    if((error) || (notFound)) return <div>Something went wrong...</div>

    // Levelbar
        var this_level_xp = state.userLevelMinXp;
        var next_level_xp = state.userLevelMaxXp;
        var user_xp = state.userCurrentXp;
        
        var xp_diff = next_level_xp - this_level_xp;//get diff between this two levels
        var current_xp = user_xp - this_level_xp;//calculate how much xp I did this level
        var current_percent = Math.floor((100 * current_xp) / xp_diff);//calculate how much percent I got in this level
    //Levelbar End

    return (
        <>
            <Wrapper>
                <div className="profile">
                    <div className="profile-right">
                        <div id="profileHeader" className="profile-header">
                            <UserImage src={(state.userImgUrl) ? ( IMG_URL + state.userImgUrl ) : ( UserDefaultImage )} alt='UserImage' className='profile-img'/>
                            <ProfileHeader>
                                <h1 className="profile-username color-5"><span>{state.userName}{ (state.userVerified == true) ? <img src={UserVerifiedImage} className='userVerifiedIcon' /> :null }</span></h1>
                                <div className="profile-levelbar-wrapper">
                                    <div className="profile-levelbar">
                                        <div id="levelbar-progress" className="profile-levelbar-progress" style={{width: current_percent+'%'}}>
                                        </div>
                                        <div className="profile-levelbar-informations">
                                            <div className="profile-levelbar-level"><GetText textName='user_level' /> <span id="levelAmount">{state.userLevel}</span></div>
                                            <div className="profile-levelbar-text"><span id="profile-levelbar-percent">{100-current_percent+'%'}</span> <GetText textName='user_up_to_level' /> <span id="levelNextAmount">{state.userNextLevel}</span></div>
                                        </div>
                                    </div>
                                </div>
                            </ProfileHeader>
                        </div>
                        <div id="profileInformations" className="profile-informations-wrapper">
                            <div className="profile-informations-row">
                                <div className="profile-informations-container">
                                    <div className="profile-informations-title"><GetText textName='user_stats' /></div>
                                    <div className="profile-informations-amount-wrapper">
                                        <center>
                                            <div className="profile-informations-amount-container">
                                                <div id="postAnswerAmount" className="profile-informations-amount">{state.userPostAnswerAmount}</div>
                                                <div className="profile-informations-amount-title"><GetText textName='user_answers' /></div>
                                            </div>
                                            <div className="profile-informations-amount-container">
                                                <div id="postAnswerActiveAmount" className="profile-informations-amount">{state.userPostActiveAnswerAmount}</div>
                                                <div className="profile-informations-amount-title"><GetText textName='user_active_answers' /></div>
                                            </div>
                                        </center>
                                    </div>
                                </div>
                                <div className="profile-informations-container color-bg-2 float-right box-shadow-1">
                                {
                                    (userName == UserFunctions.getUserName()) ?
                                        <>
                                            <div className="profile-informations-title"><GetText textName='user_last_answer' /></div>
                                            <hr/>
                                            {
                                                state.userAnswerLatest.map(row => (
                                                    <PostInfo key={row.id} postData={row} userHash={UserFunctions.getSessionHash()} />
                                                ))
                                            }
                                        </>
                                    :null
                                }
                                </div>
                            </div>
                            <div className="profile-informations-row">
                            </div>
                        </div>
                    </div>
                </div>
            </Wrapper>
        </>
    )
};

export default UserInfo;