import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
//API
import API from '../../API';
//Components
import GetText from '../GetText';
import Spinner from '../Spinner';
import Textarea from '../Textarea';
import Button from '../Button';
//Styles
import { Wrapper } from './CreatePost.style';

const CreatePost = ({ value, userHash }) => {

    const [title, setTitle] = useState(null);
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const handleInputPost = e => {
        const name = e.currentTarget.name;
        const value = e.currentTarget.value;

        if(name === 'postTextareaTitle') setTitle(value);
        
    };

    const handleSubmitPost = async () => {
        if(title) {
            try {
                setLoading(true)
                setError(false);
        
                const data = await API.createPost(title, userHash);
        
                var postUrl = data.results[0].postUrl;
                var errorMessage = data.results[0].error;

                if(postUrl) { 
                    setLoading(false);
                    navigate('/p/' + postUrl);
                }

                if(errorMessage) {
                    setLoading(false);
                    setErrorMessage(errorMessage)
                }

            } catch (error) {
                setLoading(false);
                setError(true);
            }
        }
    };

    useEffect(() => {
        if(!loading) {
            if(title) {
                return;
            }
            else
            {
                if(!value) {
                    return;
                }
                else
                {
                    setTitle(value)
                }
            }
        }

    }, [title, value, loading]);

    if(loading) return <Spinner />
    if(error) return <Wrapper>Something went wrong...</Wrapper>
    if(errorMessage) return <Wrapper><GetText textName={errorMessage} /></Wrapper>

    return (
        <Wrapper>
			<div className="post-create-wrapper">
                {
                    (userHash) ? (
                        <>
                            <div className="post-create-title">Wie heißt der Champion?</div>
                            <Textarea name="postTextareaTitle" className='post-create-textarea-title"' placeholder='' value={title} onChange={handleInputPost} />
            
                            <span id="postTextareaTitleCount" className="post-create-textarea-title-count"></span>
                            <textarea name="postTextareaText" className="post-create-textarea-text" placeholder=''></textarea>
                            <center><Button className='post-create-button color-button hover' text={<GetText textName='send' />} callback={handleSubmitPost} /></center>
                        </>
                    )
                    :null
                }
            </div>
        </Wrapper>
    )
};

export default CreatePost;