import styled from 'styled-components';

export const Wrapper = styled.div`
    margin:12px;
    animation: animatePostInfo 0.5s;

    display:inline-block;

    @keyframes animatePostInfo {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }
`;

export const Content = styled.div`
    display: inline-block;
    
    margin: 0 auto;
    border-radius: 20px;

    img {
        margin:auto;
        width:100px;
        height:100px;
        border-radius:100%;
        display:block;
    }

    @media screen and (max-width: 768px) {
        display: block;
        max-height: none;
    }

    article {
        width: 100%;
    }

    i {
        color: var(--color-4) !important;
    }

    .answerDate {
        font-size: var(--fontSuperSmall);
        color: var(--color-1) !important;
        font-weight: 800;
        display: block;
        float: left;
    }
`;