import React from 'react';

//Hooks
import { useGetTextFetch } from '../../hooks/useGetTextFetch';
//Functions
import UserFunctions from '../UserFunctions';

const GetText = ({ textName, textLang }) => {

    if(!textLang) {
        textLang = UserFunctions.getUserLanguage();
    }

    const {
        state,
        loading, 
        error,
    } = useGetTextFetch(textName, textLang);
    

    if(loading) return 'Loading ...'
    if(error) return <div>Something went wrong...</div>

    return (
        state.text
    )
};

export default GetText;