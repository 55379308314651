import React from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
// Config
import { IMG_URL } from '../../config'
//Components
import Spinner from '../Spinner';
import Vote from '../Vote';
import Moderation from '../Moderation';
import GetText from '../GetText';
// Styles
import { Wrapper as PostInfoWrapper, Content } from './PostInfo.styles';

const PostInfo = ({ postData, userHash }) => {

    const {
        loading,
        error
    } = useParams();
    
    const navigate = useNavigate();


    if(loading) return <Spinner />
    if(error) return <div>Something went wrong...</div>

    if(!postData.id) {
        navigate('/p/');
    }

    return (
        <>
            <PostInfoWrapper>
                <Content>
                    {
                        (postData.id) ? (
                        <article key={postData.id}>
                                <div className="post-wrapper post-id box-shadow-1">
                                    <Link to={`/p/${postData.url}`} className='hover'>
                                        <img alt={postData.title} src={ IMG_URL + postData.img} />

                                        <h1 id="postTitle" className="post-title">
                                        {postData.title}
                                        </h1>
                                        <div id="postText" className="post-text">
                                            <p>{postData.text}</p>
                                        </div>
                                    </Link>
                                    <div id="postAnswer" className="post-answer">
                                        {
                                            (postData.answerId && postData.answer) ? (
                                                <>
                                                    <Link to={`/p/${postData.url}`} className='hover'><p>{postData.answer}</p></Link>
                                                    <div id="postAnswerVote" className="post-answer-vote">
                                                        <Vote answerId={postData.answerId} userHash={userHash} />
                                                    </div>
                                                </>
                                            )
                                            :
                                            (
                                                <i><GetText textName='answerNotFound' /></i>
                                            )
                                        }
                                    </div>
                                    <span className='answerDate'>{(postData.answer) ? postData.answerDateDMY :null}</span>
                                    <div className='postModeration'>
                                        <Moderation postId={postData.id} answerId={postData.answerId} userHash={userHash} post={postData.title} answer={postData.answer} />
                                    </div>
                                </div>
                        </article>
                        )
                        :
                        (
                            <></>
                        )
                    }
                </Content>
            </PostInfoWrapper>
        </>
    )
};

export default PostInfo;